
import { defineComponent } from "vue"
import store from '@/store'
import Dropzone from "@/components/Dropzone.vue"
import Swal from "sweetalert2/dist/sweetalert2.js"
import axios from "axios"
import { Mutations } from "@/store/enums/StoreEnums"

export default defineComponent({
  name: "LogoManager",
  components: {
    Dropzone
  },
  data() {
    return {
      accountNum: store.getters.activeAccountInfo.id,
      accountLogo: store.getters.activeAccountInfo.details.logo_url
    }
  },
  methods: {
    fileAdded(val) {
      if (val.upload.filename.match("^.*.(jpg|JPG|png|PNG)$")) {
        console.log(val.upload.filename)
      } else {
        Swal.fire({
          text: "Please upload a .jpg or .png file",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
        return
      }

      this.sendRequest(val)
    },
    fileRemoved(val) {
      this.sendRequest(val, true)
    },
    async sendRequest(val, empty = false) {

      let data = empty ? { fileType: val.type.split("/")[1] } : {}

      const ax = axios.create({
        baseURL: process.env.VUE_APP_FOOTPRINT_API_ENDPOINT || "https://footprint-api.hlmtech.com",
        headers: { "Content-Type": !empty ? `${val.type}` : "application/json" }
      })

      let logoUpload
      try {
        logoUpload = await ax.put(`/accounts/${this.accountNum}/data/logo`, !empty ? val : data)
      } catch (e) {
        console.error("Problem uploading account logo", e)
      }

      if (logoUpload.status == 200) {
        this.accountLogo = logoUpload.data.data.attributes.logo_url
      } else if (logoUpload.status == 204) {
        this.accountLogo = null
      }

      store.commit(Mutations.SET_ACTIVE_ACCOUNT, logoUpload.data)

    }
  }
});
