
import { defineComponent, ref } from "vue"
import { ErrorMessage, Form, Field } from "vee-validate"
import store from '@/store'
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import { FeedbackSettings } from "@/core/interfaces/FeedbackSettings"
import AccountService from "@/core/services/AccountService"
import { Mutations } from "@/store/enums/StoreEnums"

export default defineComponent({
  name: "FeedbackSettings",
  components: {
    ErrorMessage,
    Form,
    Field
  },
  setup() {
    const accountNum = store.getters.activeAccountInfo.id
    const accountDetails = store.getters.activeAccountInfo.details

    const submitUpdateFeedbackSettingsRequest = ref<HTMLElement | null>(null)
    const updateSettings = ref<FeedbackSettings>({
      nps_reply_email: accountDetails.nps_reply_email,
      nps_question: accountDetails.nps_question
    })
    const sendRequest = async () => {
      ApiService.setHeader()

      let updateFeedbackSettingsResponse
      try {
        updateFeedbackSettingsResponse = await AccountService.updateAccount(accountNum, updateSettings.value)
      } catch (e) {
        console.error("Problem updating Feedback settings", e)
        return false
      }

      if (updateFeedbackSettingsResponse.data) {
        return updateFeedbackSettingsResponse.data.data
      } else {
        return false
      }

    }

    const updateFeedbackSettings = async () => {
      if (submitUpdateFeedbackSettingsRequest.value) {
        // Activate indicator
        submitUpdateFeedbackSettingsRequest.value.setAttribute("data-kt-indicator", "on")

        const response = await sendRequest()

        if (response) {
          // update store
          store.commit(Mutations.UPDATE_ACTIVE_ACCOUNT_DETAIL, updateSettings.value)
          Swal.fire({
            text: "Feedback Settings Updated!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          submitUpdateFeedbackSettingsRequest.value?.removeAttribute("data-kt-indicator")
        } else {
          Swal.fire({
            text: "Error adding contact",
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          submitUpdateFeedbackSettingsRequest.value?.removeAttribute("data-kt-indicator")
        }
      }
    }

    return {
      accountDetails,
      accountNum,
      submitUpdateFeedbackSettingsRequest,
      updateSettings,
      updateFeedbackSettings
    }
  },
  data() {
    return {
      accountName: store.getters.activeAccountInfo
    }
  }
});
