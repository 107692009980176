
import { defineComponent } from "vue"
import LogoManager from "@/components/LogoManager.vue"
import FeedbackSettings from "@/components/FeedbackSettings.vue"

export default defineComponent({
  name: "DisplaysAndFeatures",
  components: {
    LogoManager,
    FeedbackSettings
  },
});
